export const APP_ENV = {
  LOCAL: 'local',
  DEVELOPMENT: 'development',
  UAT: 'uat',
  STAGING: 'staging',
  DEMO: 'demo',
  PRODUCTION: 'production',
};

// Get list of apps from an endpoint with a json file
// The json file also looks at env to determine the appId to use

export const getEnvironment = () => {
  const url = document.location.host;
  let environment = process.env.REACT_APP_ENVIRONMENT;

  if (url.includes('localhost')) {
    environment = APP_ENV.LOCAL;
  } else if (url.includes('develop') || url.includes('dev')) {
    environment = APP_ENV.DEVELOPMENT;
  } else if (url.includes('staging') || url.includes('stg')) {
    environment = APP_ENV.STAGING;
  } else if (url.includes('uat')) {
    environment = APP_ENV.UAT;
  } else if (url.includes('demo')) {
    environment = APP_ENV.DEMO;
  } else {
    environment = APP_ENV.PRODUCTION;
  }

  return environment;
};

export const getUrl = (sitePrefix) => {
  let envSuffix = '-dev';
  const env = getEnvironment();

  if (env === APP_ENV.STAGING) {
    envSuffix = '-stg';
  } else if (env === APP_ENV.UAT) {
    envSuffix = '-uat';
  } else if (env === APP_ENV.DEMO) {
    envSuffix = '-demo';
  } else if (env === APP_ENV.PRODUCTION) {
    envSuffix = '';
  }

  return `https://${sitePrefix}${envSuffix}.nationsafedrivers.com`;
}