const INPUT_FIELD_TYPE = {
  BOOLEAN_SELECT: "booleanSelect",
  MULTI_LINE: "multiLine",
  MULTI_SELECT: "multiSelect",
  NUMBER: "number",
  PASSWORD: "password",
  PHONE: "phone",
  SELECT: "select",
  AUTOCOMPLETE_SELECT: 'autoCompleteSelect',
  TEXT: "text",
  AUTOCOMPLETE_MULTI_SELECT: "autoCompleteMultiSelect",
  BOOLEAN_CHECKBOX: "booleanCheckbox",
  DATE_TIME: "dateTime",
  INVISIBLE: "invisible",
};

export const INPUT_FIELD_TYPE_VALUES = Object.values(INPUT_FIELD_TYPE);

export default INPUT_FIELD_TYPE;
