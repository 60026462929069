import React, { useState } from 'react';
import {
  TextField,
  IconButton,
  InputAdornment,
  Chip,
  FormControlLabel,
  Checkbox,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
// import DatePicker from "@material-ui/pickers/DatePicker";
// import MuiPickersUtilsProvider from "@material-ui/pickers/MuiPickersUtilsProvider";
import DateFnsUtils from '@date-io/date-fns';
import {
  Visibility as VisibilityOnIcon,
  VisibilityOff as VisibilityOffIcon,
  Cancel as CancelIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import INPUT_FIELD_TYPE, { INPUT_FIELD_TYPE_VALUES } from '../constants/inputFieldType';
import MaskedPhoneInput from './MaskedPhoneInput';

const useStyles = makeStyles((theme) => ({
  helperText: {
    textAlign: 'right',
    marginRight: 0,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    zIndex: 100,
  },
  select: {
    minWidth: '5rem',
  },
  labelText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '7rem',
  },
}));

/**
 * Generic component for displaying the most common
 * input element types with enchancements. State for normal input
 * types have native control (not a controlled component).
 * To get the values out you need to have a piece of state higher in the tree
 * with a setter passed in onChange (through the rest prop).
 *
 * Note: onChange for the Phone input includes the rendered
 * parentheses and dashes within the string
 *
 * Note: In order to customize the phone input send in a
 * customMaskedPhoneInput component (the object, not the jsx)
 * that represents the text-mask you wish to use.
 *
 * See src/stories/InputField.stories.js (in nsd-fe) for examples
 */
const InputField = ({ type, options, label, ...otherProps }) => {
  const {
    datePickerSettings = null,
    customMaskedPhoneInput,
    // Exclusively for multi-select chip deletion
    handleDelete,
    ...rest
  } = otherProps;
  const classes = useStyles();
  let menuItems = [];
  let customProps;
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisible = () => setPasswordVisible(!passwordVisible);

  if (type === INPUT_FIELD_TYPE.PASSWORD) {
    customProps = {
      type: 'password',
      label,
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={togglePasswordVisible}
            >
              {passwordVisible ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      },
    };
  } else if (type === INPUT_FIELD_TYPE.NUMBER) {
    customProps = {
      type: 'number',
      label,
      inputProps: {
        min: '0',
      },
    };
  } else if (type === INPUT_FIELD_TYPE.PHONE) {
    customProps = {
      label: 'Phone number',
      // Since you pass in the object version you cannot
      // pass custom props into the masked input
      InputProps: {
        inputComponent: customMaskedPhoneInput || MaskedPhoneInput,
        ...rest.InputProps,
      },
    };
  } else if (type === INPUT_FIELD_TYPE.BOOLEAN_SELECT) {
    customProps = {
      SelectProps: {
        autoWidth: true,
        className: classes.select,
      },
      InputLabelProps: {
        className: classes.labelText,
        shrink: true,
      },
      select: true,
      defaultValue: 'true',
      value: 'true',
      label,
    };
    menuItems = [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ];
  } else if (type === INPUT_FIELD_TYPE.SELECT) {
    customProps = {
      select: true,
      // Does this need to be changed
      defaultValue: options?.length ? options[0] : {},
      label,
      SelectProps: {
        autoWidth: true,
        className: classes.select,
      },
      InputLabelProps: {
        className: classes.labelText,
        shrink: true,
      },
    };
    menuItems = options;
  } else if (type === INPUT_FIELD_TYPE.AUTOCOMPLETE_SELECT) {
    return (
      <Autocomplete
        options={options}
        getOptionLabel={
          rest.getOptionLabel ?? ((option) => option?.label?.toString())
        }
        getOptionSelected={
          rest.getOptionSelected ??
          ((option, newVal) => option.value === newVal.value)
        }
        defaultValue={rest.defaultValue ?? ""}
        onChange={rest.onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            placeholder={rest.placeholder}
            {...rest.textFieldProps}
          />
        )}
        {...(rest.groupBy && { groupBy: rest.groupBy })}
        {...(rest.renderOption && { renderOption: rest.renderOption })}
        {...(rest.renderGroup && { renderGroup: rest.renderGroup })}
        inputValue={rest.inputValue}
        onInputChange={rest.onInputChange}
      />
    );
  } else if (type === INPUT_FIELD_TYPE.MULTI_SELECT) {
    customProps = {
      InputLabelProps: {
        className: classes.labelText,
        shrink: true,
      },
      SelectProps: {
        autoWidth: true,
        className: classes.select,
        multiple: true,
        MenuProps: {
          getContentAnchorEl: () => null,
        },
        defaultValue: '',
        renderValue: (selected) => {
          const selectMap = options.reduce((acc, cur) => {
            acc[cur.value] = cur.label;
            return acc;
          }, {});
          return (
            <span className={classes.chips}>
              {Object.keys(selectMap).length
                ? selected?.map((value) => (
                  <Chip
                    key={value}
                    label={selectMap[value]}
                    className={classes.chip}
                    onDelete={() => handleDelete(value)}
                    deleteIcon={
                      <CancelIcon
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    }
                  />
                ))
                : null}
            </span>
          );
        },
      },
      select: true,
      label,
    };
    menuItems = options;
  } else if (type === INPUT_FIELD_TYPE.MULTI_LINE) {
    customProps = {
      multiline: true,
      rows: 2,
      FormHelperTextProps: { classes: { root: classes.helperText } },
      label,
    };
  } else if (type === INPUT_FIELD_TYPE.DATE_TIME) {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          {...customProps}
          onChange={(val) => {
            rest.onChange({ target: { name: rest.name, value: val } });
          }}
          value={rest.value || null}
          format="MM/dd/yyyy"
          inputVariant={rest.variant}
          clearable
          autoOk
          views={['year', 'month', 'date']}
          label={label}
          initialFocusedDate={new Date()}
          {...(datePickerSettings && datePickerSettings)}
        />
      </MuiPickersUtilsProvider>
    );
  } else if (type === INPUT_FIELD_TYPE.BOOLEAN_CHECKBOX) {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={rest.value || false}
            onChange={rest.onChange}
            name={rest.name}
            color="primary"
          />
        }
        label={rest.label}
      />
    );
  } else if (type === INPUT_FIELD_TYPE.AUTOCOMPLETE_MULTI_SELECT) {
    return (
      <Autocomplete
        multiple
        options={options}
        getOptionLabel={
          rest.getOptionLabel ?? ((option) => option.label.toString())
        }
        getOptionSelected={
          rest.getOptionSelected ??
          ((option, newVal) => option.value === newVal.value)
        }
        value={rest.value ?? undefined}
        defaultValue=""
        onChange={rest.onChange}
        disableCloseOnSelect
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            placeholder={rest.placeholder}
            {...rest.textFieldProps}
          />
        )}
        {...(rest.groupBy && { groupBy: rest.groupBy })}
        {...(rest.renderOption && { renderOption: rest.renderOption })}
        {...(rest.renderGroup && { renderGroup: rest.renderGroup })}
        inputValue={rest.inputValue}
        onInputChange={rest.onInputChange}
      />
    );
  } else if (type === INPUT_FIELD_TYPE.INVISIBLE) {
    return null;
  }

  // Map our custom types to a valid HTML5 <input type=""/> value
  const getFinalType = () => {
    switch (type) {
      case 'booleanSelect':
      case 'multiSelect':
        return 'select';
      case 'multiLine':
      case 'phone':
      case 'password':
      case 'dateTime':
      case 'autoCompleteMultiSelect':
        return 'text';
      case 'booleanCheckbox':
        return 'checkbox';
      default:
        return type;
    }
  };
  const finalType = getFinalType();
  return (
    <TextField
      label={label}
      {...rest}
      {...customProps}
      size="medium"
      type={
        type === INPUT_FIELD_TYPE.PASSWORD
          ? passwordVisible
            ? 'text'
            : 'password'
          : finalType
      }
    >
      {menuItems?.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </TextField>
  );
};

InputField.propTypes = {
  /**
   * Array of options to be used if a select input type is
   * choosen
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.oneOf(['all']),
        // Date() object from js
        PropTypes.object,
      ]),
      label: PropTypes.string.isRequired,
    })
  ),
  /**
   * Enum of possible strings representing the different types
   * of inputs to render
   */
  type: PropTypes.oneOf(INPUT_FIELD_TYPE_VALUES),
  /**
   * Label for the input
   */
  label: PropTypes.string,
};

InputField.defaultProps = {
  options: null,
  type: 'text',
  label: 'Label text',
};

export default InputField;
